import type { Subscription } from "@/types/subscription";
import Text from "@/components/atoms/text/Text.tsx";
import { useSubscriptionFilterStore } from "@/store/subscriptionFilterStore.ts";
import { useCallback, useEffect, useMemo, useState } from "react";
import Group from "@/components/atoms/Group.tsx";
import { Button } from "@/components/ui/button.tsx";
import { useSubscriptionFilterDrawerStore } from "@/store/drawers/filterDrawerStore.ts";
import SubscriptionFilterDrawer from "@/components/drawers/SubscriptionFilterDrawer.tsx";
import buildSubscriptionGROQ, {
  type SubscriptionOrder,
} from "@/lib/utils/sanity/subscriptionGROQBuilder.ts";
import {
  initialSubscriptionFilter,
  type SubscriptionFilter,
} from "@/lib/schemas/filter.schema.ts";
import SubscriptionsFilter from "@/components/sections/SubscriptionsFilter.tsx";
import SubscriptionCard from "@/components/organisms/cards/SubscriptionCard.tsx";
import { H3 } from "@/components/atoms/text/Headings.tsx";
import Stack from "@/components/atoms/Stack.tsx";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import IconAdjustments from "@/components/icons/IconAdjustments.tsx";
import { Loader2 } from "lucide-react";

interface SubscriptionsProps {
  subscriptions?: Subscription[];
  filter?: SubscriptionFilter;
  isPartner?: boolean;
}

const Subscriptions = ({
  isPartner = false,
  subscriptions: loadedSubscriptions,
  filter: loadedFilter,
}: SubscriptionsProps) => {
  const [subscriptions, setSubscriptions] = useState<
    Subscription[] | undefined
  >(loadedSubscriptions);
  const open = useSubscriptionFilterDrawerStore((state) => state.open);
  const { filter, setFilter, order, setOrder } = useSubscriptionFilterStore();
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const handleMouseMove = () => {
      setHasInteracted(true);
    };

    window.addEventListener("mousemove", handleMouseMove);
    window.addEventListener("touchmove", handleMouseMove);
    window.addEventListener("scroll", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      window.removeEventListener("touchmove", handleMouseMove);
      window.removeEventListener("scroll", handleMouseMove);
    };
  }, []);

  useEffect(() => {
    if (loadedFilter && isInitialLoad) {
      setFilter(loadedFilter);
      setIsInitialLoad(false);
    }
  }, [loadedFilter, setFilter, isInitialLoad]);

  useEffect(() => {
    if (!hasInteracted) return;

    const fetchSubscriptions = async () => {
      setIsLoading(true);

      try {
        const data = await buildSubscriptionGROQ(
          { ...filter, isPartner: isPartner },
          order,
          true,
        );
        setSubscriptions(data);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    void fetchSubscriptions();
  }, [filter, order, isInitialLoad, hasInteracted]);

  const isFilterChanged = useMemo(() => {
    const oldFilter = JSON.stringify({
      ...initialSubscriptionFilter,
      type: "",
    });
    const newFilter = JSON.stringify({ ...filter, type: "" });

    return oldFilter === newFilter;
  }, [filter]);

  const handleFilterReset = useCallback(() => {
    setFilter(initialSubscriptionFilter);
  }, [setFilter, initialSubscriptionFilter]);

  return (
    <>
      <SubscriptionFilterDrawer />
      <div
        id={"subscriptions"}
        className={"grid grid-cols-3 gap-3 md:gap-6 md:mx-auto w-full"}
      >
        <div className={"col-span-3 flex flex-row gap-2 justify-between"}>
          <Group>
            <Button
              variant={isFilterChanged ? "secondary" : "outline"}
              className={
                isFilterChanged
                  ? "bg-gradient-to-l from-fuchsia-400 to-fuchsia-500 text-white border-none shadow"
                  : ""
              }
              onClick={() => open(!isPartner)}
            >
              <Group gap={"xs"}>
                Filter
                <IconAdjustments />
              </Group>
            </Button>
            <Button
              onClick={handleFilterReset}
              variant={"ghost"}
              className={"w-full hidden md:block"}
            >
              Tilbakestill filter
            </Button>
          </Group>
          <Group gap={"md"}>
            <Text className={"hidden md:block"} size={"sm"} variant={"subtle"}>
              Viser {subscriptions?.length} abonnementer
            </Text>
            <Select
              value={`${order.by}|${order.direction}`}
              onValueChange={(value) => {
                const [by, direction] = value.split("|");
                setOrder({
                  by: by as SubscriptionOrder["by"],
                  direction: direction as SubscriptionOrder["direction"],
                });
              }}
            >
              <SelectTrigger className="w-fit">
                <SelectValue placeholder="Sorter" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="price.monthly|asc">
                  Pris (billigst)
                </SelectItem>
                <SelectItem value="price.monthly|desc">
                  Pris (dyrest)
                </SelectItem>
                <SelectItem value="attribute.data|asc">
                  Data (minst data)
                </SelectItem>
                <SelectItem value="attribute.data|desc">
                  Data (mest data)
                </SelectItem>
              </SelectContent>
            </Select>
          </Group>
        </div>
        <div
          className={"col-span-1 sticky top-6 self-start w-full hidden md:flex"}
        >
          <SubscriptionsFilter showNKOM={!isPartner} />
        </div>
        <div className={"col-span-3 md:col-span-2 flex flex-col gap-4 w-full"}>
          {isLoading ? (
            <Stack gap={"xs"} align={"center"} justify={"center"}>
              <Loader2 className={"h-8 w-8 animate-spin"} />
            </Stack>
          ) : subscriptions?.length === 0 ? (
            <Stack gap={"xs"} align={"center"} justify={"center"}>
              <H3>Oops! 😅</H3>
              <Text className={"text-center"}>
                Vi fant ingen abonnementer som matchet filteret du har valgt!
              </Text>
              <Button
                onClick={handleFilterReset}
                variant={"default"}
                className={"mt-3"}
              >
                Tilbakestill filter
              </Button>
            </Stack>
          ) : (
            subscriptions?.map((subscription) => (
              <SubscriptionCard
                key={subscription._id}
                subscription={subscription}
              />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
